import React, {useState} from 'react'
import {toAbsoluteUrl} from '../AssetHelpers'
import {generateAddress} from '../addressGenerator'
import {configCategoryMap} from '../dataExamples'
import {ViewImageModal} from '../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/ViewImageModal'

const configMapData = configCategoryMap

function clipText(text, maxLength = 35, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
}

const accessNestedProperty = (obj, path) => {
  return path.split('[').reduce((acc, key) => {
    return acc && acc[key.replace(']', '')]
  }, obj)
}

const checkIsTrueOrFalse = (data, name) => {
  return data.includes(name) ? 'Yes' : 'No'
}

export const ReturnData = (mapData, user, ref?: any, itemIdForUpdate?: any) => {
  const [showImageModal, setshowImageModal] = useState({
    show: false,
    clicked: '',
  })
  const filterImage = (imagesList, name) => {
    return imagesList.filter((x) => x.imageType === name)?.[0]?.imageType || []
  }

  const handleClick = (image) => {
    ref?.current?.open(image, itemIdForUpdate)
  }
  if (showImageModal.show) {
    return (
      <ViewImageModal
        close={() => setshowImageModal({show: false, clicked: ''})}
        images={
          showImageModal.clicked === 'vehicleImages'
            ? user?.pickupInfo?.vehicleDetails?.vehicleImages
            : showImageModal.clicked === 'debrisImagePathList'
            ? user.debrisImagePathList
            : user.images
        }
        name={showImageModal.clicked}
      />
    )
  }

  if (mapData.includes('[')) return accessNestedProperty(user, mapData)
  switch (mapData) {
    case 'operationDaysList':
      const configMapDay: any = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
      return (
        <div className='row'>
          {user?.companyDetails?.operatingDays?.map((eachData, idx) => {
            return (
              <div className={`${eachData === 'true' ? 'fw-bold' : ''} col col-lg-1`}>
                {configMapDay[idx]}
              </div>
            )
          })}
        </div>
      )
    case 'createdAtData':
      return user.createdAt
    case 'kycDocument':
      return ''
    case 'categoryMap':
      return <div>{configMapData[user.categoryId] || ''}</div>
    case 'format':
    case 'contactDetail':
      return (
        <div>
          <div>{user.personalDetails.mobile}</div>
          <div>{user.personalDetails.email}</div>
        </div>
      )
    case 'quantityDeposit':
      const totalQuantity =
        user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) || 0
      return totalQuantity
    case 'collectionPoints':
      const filterData = user?.collectionPoints?.map((x) => x.collectionPointName) || []
      return filterData.join(', ')
    case 'status':
      return (
        <span className={`text-${user.status === 'APPROVED' ? 'success' : 'danger'} fs-7 fw-bold`}>
          {user.status}
        </span>
      )
    case 'wastage':
      return (
        <span className='text-danger fs-7 fw-bold'>{user.wastage === 0 ? '0' : user?.wastage}</span>
      )
    case 'wasteType':
      return (
        <span
          className={`badge badge-light-${
            user[mapData] === 'MRF_WASTE' ? 'success' : 'danger'
          } fs-7 fw-bold`}
        >
          {user[mapData]}
        </span>
      )
    case 'methodology':
      return 'Mass Balance'
    case 'ISO 22095':
      return 'ISO 22095'
    case 'noOfBags':
      return user?.orderDetails?.quantity || ''
    case 'images':
      return (
        (user?.images?.length && (
          <div
            onClick={() =>
              setshowImageModal({
                show: true,
                clicked: mapData,
              })
            }
          >
            {/* {filterImage(user.images, mapData)?.length ? 'click to view' : '-'} */}
            {user.images?.length ? 'click to view' : '-'}
          </div>
        )) ||
        '-'
      )
    case 'entryTime':
    case 'exitTime':
      return (
        (user.vehicleDetails[mapData] &&
          new Date(user.vehicleDetails[mapData]).toLocaleTimeString()) ||
        '-'
      )
    case 'processName':
      // return <span className={`badge badge-light-info fs-7 fw-bold`}>{user[mapData]}</span>
      return (
        <span className={`badge badge-light-info fs-7 fw-bold`}>{user?.processName || ''}</span>
      )
    case 'details':
    case 'inMaterials':
    case 'outMaterials':
    case 'inputMaterial':
    case 'productionItemDetails':
      return user[mapData]?.map(
        (x: any, ind: number) =>
          (
            <div key={ind + 1 + ''}>
              {x?.name === null ? '' : x?.name || x?.itemName || x?.inputMaterialName || x}
            </div>
          ) || ''
      )
    case 'inMaterialDetailsQuantity':
    case 'productionItemDetailsQuantity':
      return user[
        mapData === 'inMaterialDetailsQuantity' ? 'inputMaterial' : 'productionItemDetails'
      ]?.map((x: any, ind: number) => (
        <div key={ind + 1 + ''}>{x?.quantity || x?.inputQuantity} kgs</div>
      ))
    case 'hubAddr':
      return user?.hubInfo?.address?.city || ''
    case 'countrySpec':
      return user?.customerInfo?.address?.country
    case 'addStrCity':
      return user?.customerInfo?.address?.street + ' , ' + user?.customerInfo?.address?.city
    case 'country':
    case 'city':
    case 'street':
    case 'state':
    case 'landmark':
    case 'zipCode':
    case 'latitute':
    case 'longitute':
      return user?.address?.[mapData] || ''
    case 'geoLocation':
      return user.address.latitute + ' , ' + user.address.longitute
    case 'address':
      const addressConcat = generateAddress(
        user.address || user.pickupInfo?.address || user.dropOffPointInfo?.address
      )
      return (
        <div data-bs-toggle='tooltip' title={addressConcat}>
          {clipText(addressConcat, 25)}
        </div>
      )
    case 'manager':
      return user?.companyDetails?.name || ''
    case 'pickupInfoName':
      return user?.pickupInfo?.name || ''
    case 'pickupDate':
      return new Date(+user?.pickupInfo?.pickupDate || new Date()).toLocaleDateString()
    case 'netWeight_green':
      return (
        <span className={`badge badge-light-success fs-7 fw-bold`}>{user?.netWeight + ' k.g'}</span>
      )
    case 'netWeight':
      return (
        <span className={`badge badge-light-primary fs-7 fw-bold`}>
          {user?.[mapData]?.toFixed(2) + ' k.g'}
        </span>
      )
    case 'grossWeight':
    case 'tareWeight':
      return user[mapData] + ' k.g'
    case 'createdAt':
    case 'productionId':
      return new Date(+user?.createdAt || user?.productionId || new Date()).toLocaleDateString()
    case 'receivingAt':
      return new Date(+user?.materialRecInfo?.receivingAt || new Date()).toLocaleDateString()
    case 'inMaterials':
    case 'outMaterials':
      return user[mapData]?.map((x: any, ind: number) => <div key={ind + 1 + ''}>{x.name}</div>)
    case 'DATA_ADMIN':
    case 'OI_COLLECTOR':
    case 'MATERIAL_RECEIVING':
    case 'MATERIALINSPECTION':
    case 'MOBILE_APP':
    case 'OPERATIONS':
    case 'APPROVAL':
    case 'INVENTORY':
    case 'MY_TRIPS':
    case 'INVENTORY':
      return checkIsTrueOrFalse(user.permissions, mapData)
    case 'icon':
      return (
        <div className='symbol symbol-circle'>
          {user?.icon ? (
            <img
              onClick={() => handleClick(user?.icon || toAbsoluteUrl(`/media/avatars/blank.png`))}
              src={user?.icon || ''}
              alt={user.name}
            />
          ) : (
            ''
          )}
        </div>
      )
    case 'POI':
    case 'POE':
      const value = user?.kycDocument?.filter((x) => x?.docType === mapData)?.[0]?.docNumber || ''
      return (
        <div className=''>
          <div className='symbol-label'>{value}</div>
        </div>
      )
    case 'selfie':
    case 'docUrl':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              onClick={() =>
                handleClick(
                  user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)
                )
              }
              src={user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    case 'avatar':
    default:
      return typeof user[mapData] === 'boolean'
        ? user[mapData]
          ? 'Yes'
          : 'No'
        : clipText(user?.[mapData]) || clipText(user?.personalDetails?.[mapData]) || ''
  }
}
