/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser, updateUser} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {DeleteConfirmationModal} from './DeleteModal'
import {successToast, errorToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {useLocation} from 'react-router'
import GenerateQrCode from './GenerateQrCode'
import {useAuth} from '../../../../../auth'
import React from 'react'
type Props = {
  id: any
  action: any
}

const UserActionsCell: FC<Props> = ({id, action = ['edit']}) => {
  const {currentUser, auth}: any = useAuth()
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (showView = false) => {
    setItemIdForUpdate({...id, showView})
  }

  const deleteConfirmation = () => {
    setShowDeleteModal(true)
  }

  const {pathname} = useLocation()
  const getQueryForDelete = () => {
    if (pathname.includes('role-master')) return `roles/${id.id}/status/DELETED`
    if (pathname.includes('brand')) return `brands/${id.id}/status/DELETED`
    if (pathname.includes('drop-off-point')) return `users/${id.id}/status/DELETED`
    if (pathname.includes('ocean-hub-directory')) return `users/${id.id}/status/DELETED`
    if (pathname.includes('process-name')) return `materialProcesses/${id.id}/status/DELETED`
    if (pathname.includes('quality-remarks')) return `remarks/${id.id}/status/DELETED`
    if (pathname.includes('category')) return `categories/${id.id}/status/DELETED`
    if (pathname.includes('raw-material-type')) return `items/${id.id}/status/DELETED`
    if (pathname.includes('finished-goods-type')) return `items/${id.id}/status/DELETED`
    if (pathname.includes('downtime')) return `downtimes/${id.id}/status/DELETED`
    if (pathname.includes('shift')) return `shifts/${id.id}/status/DELETED`
    if (pathname.includes('delivery-terms')) return `deliveryterms/${id.id}/status/DELETED`
    if (pathname.includes('unit-measurements')) return `uams/${id.id}/status/DELETED`
    if (pathname.includes('recycler-code')) return `users/${id.id}/status/DELETED`
    if (pathname.includes('vehicle')) return `vehicles/${id.id}/status/DELETED`
    // unit-measurements

    return ''
  }
  const isProductPassport = pathname.includes('product-passport')
  const blockedEdit = currentUser.userType === 'HUB_USER'
  const blockEditActionList = [
    '/apps/data-administrations/role-master',
    '/apps/data-administrations/hub-user',
    '/apps/data-administrations/drop-off-point',
    '/apps/data-administrations/ocean-hub-directory',
    '/apps/data-administrations/category',
    '/apps/data-administrations/raw-material-type',
    '/apps/data-administrations/process-name',
    '/apps/data-administrations/quality-remarks',
    '/apps/data-administrations/shift',
    '/apps/data-administrations/recycler-code',
    '/apps/data-administrations/delivery-terms',
    '/apps/data-administrations/vehicle',
  ].includes(pathname)
  const deleteItem = useMutation(() => deleteUser(getQueryForDelete()), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (dat) => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      setShowDeleteModal(false)
      successToast('Deleted')
    },
    onError: (error: any) => {
      errorToast(error?.message)
    },
  })

  const toggleStatus = useMutation(
    () =>
      updateUser(
        {},
        `user/${id.id}/status?type=${state.type}&status=${
          id.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        }`
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      },
    }
  )
  const [showQrCode, setShowQrCode] = useState(false)
  return (
    <>
      {(action.includes('view_certificate') && (
        <a
          onClick={() => openEditModal()}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='eye' className='fs-3' />
        </a>
      )) ||
        false}
      {showDeleteModal && (
        <DeleteConfirmationModal
          confirm={async () => await deleteItem.mutateAsync()}
          close={() => setShowDeleteModal(false)}
        />
      )}
      {showQrCode && <GenerateQrCode data={id} close={() => setShowQrCode(false)} />}
      <div className='align-items-center min-w-125px'>
        {(action.includes('view') && (
          <a
            {...((isProductPassport && {
              href: process.env.REACT_APP_BASE_API_URL + `${id.createdAt}/chainOfCustody?page=1`,
              target: '_blank',
            }) ||
              '')}
            onClick={() => openEditModal(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            rel='noreferrer'
          >
            <KTIcon iconName='eye' className='fs-3' />
          </a>
        )) ||
          false}

        {/* {(action.includes('download') && (
          <>
            <a
              onClick={() => handleDownload()}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='file-down' className='fs-3' />
            </a>
          </>
        )) ||
          false} */}
        {(blockEditActionList && blockedEdit
          ? ''
          : action.includes('edit') && (
              <a
                {...((pathname.includes('collection-management') &&
                  id.status === 'APPROVED' && {
                    style: {pointerEvents: 'none', cursor: 'not-allowed', opacity: '0.1'},
                  }) ||
                  '')}
                onClick={() => openEditModal()}
                className={` btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
              >
                <KTIcon iconName='pencil' className='fs-3' />
              </a>
            )) || false}

        {(blockEditActionList && blockedEdit
          ? ''
          : action.includes('delete') &&
            action.includes('edit') && (
              <a
                // onClick={async () => await deleteItem.mutateAsync()}
                onClick={() => deleteConfirmation()}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            )) || false}

        {/* {action.includes('delete') && action.includes('edit') && (
          <a
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={() => deleteConfirmation()}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTIcon iconName='trash' className='fs-3' />
          </a>
        )} */}
        {(action.includes('toggle') && (
          <div className='form-check form-check-solid form-switch fv-row'>
            <input
              className='form-check-input'
              type='checkbox'
              id='allowmarketing'
              defaultChecked={id.status === 'ACTIVE' ? true : false}
              onChange={async () => {
                await toggleStatus.mutateAsync()
              }}
            />
          </div>
        )) ||
          false}

        {(action.includes('qr') && (
          <a
            onClick={() => setShowQrCode(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='code' className='fs-3' />
          </a>
        )) ||
          false}
      </div>
    </>
  )
}

export {UserActionsCell}
