import {useEffect} from 'react'
import {useListView} from '../../core/ListViewProvider'
import {UserEditModalHeader} from '../../form-edit-modal/UserEditModalHeader'
import {KTSVG} from '../../../../../../../_metronic/helpers'

interface ModalProps {
  formName?: string
  headerName?: string
  viewPage?: string
}

const DeleteConfirmationModal = ({close, confirm}: any) => {
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        {/* <div className='modal-dialog modal-dialog-centered mw-650px'> */}
        <div
          className={`modal-dialog 
           mw-650px modal-dialog-centered`}
        >
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* <UserEditModalHeader headerName={'Confirmation'} /> */}
            <div className='modal-header'>
              <h5 className='modal-title'>Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={close}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div>Are you sure you want to delete ?</div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                onClick={close}
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button onClick={confirm} type='button' className='btn btn-primary'>
                Confirm
              </button>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {DeleteConfirmationModal}
