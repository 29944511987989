import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import React from 'react'
import ProtectedSpecificRoute from './NestedPrivateRoutes'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const DataAdministrationPage = lazy(
    () => import('../modules/apps/data-administration/DataAdminstrationPage')
  )
  const OperationManagementPage = lazy(
    () => import('../modules/apps/operations-management/OperationManagementPage')
  )
  const Inventory = lazy(() => import('../modules/apps/inventory/InventoryPage'))

  const Dispatch = lazy(() => import('../modules/apps/dispatch/DispatchPage'))

  const Production = lazy(() => import('../modules/apps/productions/ProductionsPage'))

  const SustainableDeclarationPage = lazy(
    () => import('../modules/apps/sustainable-declaration/SustainableDeclarationPage')
  )

  // const InventoryPage = lazy(
  //   () =>
  //     import(
  //       '../modules/apps/data-administration/data-admininstration-list/raw-material-inventory-list'
  //     )
  // )
  const BatchManagementPage = lazy(
    () => import('../modules/apps/batch-management/BatchManagementPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='data-administration' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='/apps/*' element={<UsersPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            // <ProtectedSpecificRoute>
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
            // </ProtectedSpecificRoute>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <Inventory />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/data-administrations/*'
          element={
            // <ProtectedSpecificRoute>
            <SuspensedView>
              <DataAdministrationPage />
            </SuspensedView>
            // </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/operations-management/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <OperationManagementPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/product-passport/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <BatchManagementPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/inventory/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <Inventory />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/dispatch/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <Dispatch />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/production/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <Production />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        <Route
          path='apps/sustainable-declaration/*'
          element={
            <ProtectedSpecificRoute>
              <SuspensedView>
                <SustainableDeclarationPage />
              </SuspensedView>
            </ProtectedSpecificRoute>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
