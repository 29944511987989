/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const hideSideBar = auth?.data?.userType === 'ADMIN' || false
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      {hideSideBar ? (
        <>
          <SidebarMenuItem
            to='/apps/data-administrations'
            icon='abstract-28'
            title='Data Administration'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        <>
          <SidebarMenuItem
            to='/apps/data-administrations'
            icon='abstract-28'
            title='Data Administration'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/operations-management'
            icon='element-7'
            title='Operations Management'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/inventory'
            icon='element-5'
            title='Inventory'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/production'
            icon='element-7'
            title='Production'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/dispatch'
            icon='element-7'
            title='Dispatch'
            fontIcon='bi-layers'
          />
          {/*  <SidebarMenuItem
            to='/apps/sustainable-declaration'
            icon='element-11'
            title='Sustainable Declaration'
            fontIcon='bi-layers'
          /> */}
          <SidebarMenuItem
            to='/apps/product-passport'
            icon='element-12'
            title='Product Passport'
            fontIcon='bi-layers'
          />
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
