/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  title?: string
  data?: any
  headerName?: any
}

const ListsWidget2: React.FC<Props> = ({
  data = [],
  className,
  headerName = 'Collection',
  title = 'Top 5 collection points',
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{headerName}</span>
          <span className='text-muted fw-semibold fs-7'>{title}</span>
        </h3>
        {/* <h3 className='card-title fw-bold text-dark'>Leaderboard</h3>
        <span className='text-muted fw-semibold fs-7'>890,344 Sales</span> */}
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button> */}
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {data.map((eachData, ind) => (
          <div key={ind + 1 + ''} className='d-flex align-items-center mb-7'>
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl(eachData.icon)} className='' alt='' />
            </div>
            <div className='flex-grow-1'>
              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                {eachData.name}
              </a>
              <span className='text-muted d-block fw-semibold'>{eachData.city}</span>
            </div>
          </div>
        ))}

        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget2}
