import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import React from 'react'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-2 order-2 order-lg-1'
        style={{background: '#007297'}} //#4D93B2
      >
        {/* begin::Form */}
        <div /*className='d-flex justify-content-center'*/>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/PWS Cebu_.png')}
            style={{
              height: '50px !important',
              width: '200px',
              // width: '50px',
              marginLeft: '-20px',
              marginTop: '-44px',
            }}
            //className='h-200px'
          />
        </div>
        <div className='d-flex flex-center flex-column p-20 flex-lg-row-fluid'>
          <div
            style={{
              fontSize: '42px',
              marginBottom: '42px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              color: 'white',
            }}
          >
            Good to see you again
          </div>
          {/* begin::Wrapper */}
          <div className='w-lg-500px'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <Link to='#' className='px-5' target='_blank'>
              Terms
            </Link>
            <Link to='#' className='px-5' target='_blank'>
              Contact Us
            </Link>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover order-1 order-lg-2 overflow-hidden position-relative'>
        <img
          src={toAbsoluteUrl('/media/logos/asideImage.jpg')}
          alt='Aside Image'
          className='position-fixed w-100 h-100'
          style={{objectFit: 'cover'}}
        />
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
