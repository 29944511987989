/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  title?: string
  subTitle?: string
  data?: any
}

const PieCharts: React.FC<Props> = ({
  className,
  subTitle = '',
  title = 'Recent Statistics',
  data,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [pieValue, setPieValue] = useState<any>([0, 100])
  useEffect(() => {
    if (data) {
      console.log('first')
      setPieValue([data, 100 - data])
    }
  }, [data])
  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, pieValue])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, pieValue))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subTitle}</span>
        </h3>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '265px'}} />
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PieCharts}

function getChartOptions(height: number, pieValue: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  return {
    series: pieValue,
    chart: {
      width: 380,
      type: 'pie',
    },
    legend: {
      //   show: false,
      position: 'bottom',
    },
    labels: ['Recycled', 'UnRecycled'],
  }
}
