import {Navigate} from 'react-router-dom'
import {useAuth} from '../modules/auth'
import React from 'react'

const ProtectedSpecificRoute = ({children}) => {
  const {auth} = useAuth()

  if (auth?.data?.userType === 'HUB_USER') {
    return children
  }
  return <Navigate to='/dashboard' replace />
}

export default ProtectedSpecificRoute

// userId
