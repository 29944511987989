/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  PieCharts,
  ChartsWidget1,
  ChartsWidget8,
} from '../../../_metronic/partials/widgets'
import {KTIcon} from '../../../../src/_metronic/helpers'

import {StatisticsWidget5} from '../../../_metronic/partials/widgets'
import React from 'react'
import {useQuery} from 'react-query'
import {getUserById} from '../../modules/apps/data-administration/data-admininstration-list/core/_requests'

const numberItems = [
  {name: 'Collected', icon: 'Collected', color: '#ffffff', value: 'collected'},
  {name: 'Processed', icon: 'Production', color: '#ffffff', value: 'processed'},
  {name: 'Dispatched', icon: 'Dispatched', color: '#ffffff', value: 'dispatched'},
  {name: 'MRF Stock', icon: 'Stock', color: '#ffffff', value: 'mrfWaste'},
  {name: 'Pending Decision', icon: 'Customer', color: '#ffffff', value: 'mrfWaste'},
  {name: 'Residuals', icon: 'Residuaks', color: '#ffffff', value: 'landfillWaste'},
  {name: 'Staff', icon: 'Staff', color: '#ffffff', value: 'resourceRecovery'},
  {name: 'C02 Avoided', icon: 'emission', color: '#ffffff', value: 'coprocessingRatio'},
]

const DashboardPage: FC = () => {
  const {data = {}} = useQuery(`analytics`, () => getUserById('', 'analytics'), {
    cacheTime: 0,
    onError: (err) => {
      console.warn(err)
    },
  })

  const {
    collectionDetails,
    productionDetails,
    monthlyCollectionDetails,
    monthlyDispatchDetails,
    monthlyProductionDetails,
    coprocessingRatio,
    recyclingRatio,
  } = data

  const mapDate = [
    `${new Date().getFullYear()}-01`,
    `${new Date().getFullYear()}-02`,
    `${new Date().getFullYear()}-03`,
    `${new Date().getFullYear()}-04`,
    `${new Date().getFullYear()}-05`,
    `${new Date().getFullYear()}-06`,
    `${new Date().getFullYear()}-07`,
    `${new Date().getFullYear()}-08`,
    `${new Date().getFullYear()}-09`,
    `${new Date().getFullYear()}-10`,
    `${new Date().getFullYear()}-11`,
    `${new Date().getFullYear()}-12`,
  ]
  const updateGraphData = (trendData, setGraphData, graphData) => {
    if (trendData && Object.keys(trendData)?.length > 0) {
      console.log({mapDate})
      const test = mapDate.map((x) => trendData[x]?.toFixed(0) || '0')
      setGraphData({...graphData, monthValue: test})
    }
  }

  const commonFormatData = (data: any, setGraphData: any) => {
    let response = data
    let keysArray = Object.keys(response)
    let valuesArray = Object.values(response)
    setGraphData({date: keysArray, data: valuesArray})
  }

  const [collectedGraphData, setCollectedGraphData] = useState<any>({})

  const [productGraphData, setProductGraphData] = useState<any>({})

  const [collectionDetailsData, setCollectionDetailsData] = useState<any>({})
  const [dispatchData, setDispatchData] = useState<any>({})
  const [productionData, setProductionData] = useState<any>({})
  useEffect(() => {
    if (monthlyCollectionDetails) {
      updateGraphData(monthlyCollectionDetails, setCollectionDetailsData, collectionDetailsData)
    }
  }, [monthlyCollectionDetails])
  useEffect(() => {
    if (monthlyDispatchDetails) {
      updateGraphData(monthlyDispatchDetails, setDispatchData, dispatchData)
    }
  }, [monthlyDispatchDetails])
  useEffect(() => {
    if (monthlyProductionDetails) {
      updateGraphData(monthlyProductionDetails, setProductionData, productionData)
    }
  }, [monthlyProductionDetails])

  useEffect(() => {
    if (collectionDetails) {
      commonFormatData(collectionDetails, setCollectedGraphData)
    }
  }, [collectionDetails])

  useEffect(() => {
    if (productionDetails) {
      commonFormatData(productionDetails, setProductGraphData)
    }
  }, [productionDetails])

  return (
    <>
      <div className='row g-xl-4'>
        {numberItems.map((eachitems, eachIndex) => (
          <div key={eachIndex + 1 + ''} className='col-xl-3'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              img={eachitems.icon}
              color={eachitems?.color || 'warning'}
              iconColor='primary'
              title={`
                
                 ${
                   eachitems.name === 'Pending Decision'
                     ? (data?.collected - data?.approvedCollections)?.toFixed(2)
                     : data?.[eachitems.value]?.toFixed(
                         ['collectionPoints', 'wasteDiverters', 'lifeImpacted'].includes(
                           eachitems.value
                         )
                           ? 0
                           : 2
                       ) || ''
                 }
                 ${
                   ['collectionPoints', 'wasteDiverters', 'lifeImpacted'].includes(eachitems.value)
                     ? ''
                     : ' kg'
                 }
               `}
              // titleColor='primary'
              description={eachitems.name}
              // descriptionColor='primary'
            />
          </div>
        ))}
      </div>
      <div className='row g-2 g-xl-8' style={{marginTop: '4px'}}>
        <div className=''>
          <ChartsWidget1
            data={collectionDetailsData?.monthValue}
            title='Monthly waste collection trend'
            className='card-xl-stretch mb-xl-8'
            subTitle='Volumes in Metric Ton'
          />
        </div>
        <div className=''>
          <ChartsWidget1
            data={productionData?.monthValue}
            title='Monthly production trend'
            className='card-xl-stretch mb-xl-8'
            subTitle='Volumes in Metric Ton'
          />
        </div>
        <div className=''>
          <ChartsWidget1
            data={productionData?.monthValue}
            title='Monthly dispatch trend'
            className='card-xl-stretch mb-xl-8'
            subTitle='Volumes in Metric Ton'
          />
        </div>
      </div>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-6'>
          <ListsWidget2
            className='mb-5 mb-xxl-8'
            data={[
              {name: 'John Smith ', icon: '/media/avatars/300-1.jpg', city: 'Melton, Australia'},
              {
                name: 'Varsha Modi Jain',
                icon: '/media/avatars/300-2.jpg',
                city: 'Turnhout, Belgium',
              },
              {name: 'Diki Kusmayadi', icon: '/media/avatars/300-3.jpg', city: 'Agadir, Morocco'},
              {name: 'Ghan Shyam', icon: '/media/avatars/300-4.jpg', city: 'Tilburg, Netherlands'},
            ]}
          />
        </div>
        <div className='col-xl-6'>
          <PieCharts className='mb-5 mb-xxl-8' title='Waste composition' />
        </div>
      </div>

      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-6'>
          <PieCharts data={recyclingRatio} className='mb-5 mb-xxl-8' title='Recycling' />
        </div>
        <div className='col-xl-6'>
          <PieCharts data={coprocessingRatio} className='mb-5 mb-xxl-8' title='Co Processing' />
        </div>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget8
            data={collectedGraphData}
            title='Incoming Materials'
            className='card-xl-stretch mb-xl-8'
          />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget8
            data={productGraphData}
            title='Production'
            className='card-xl-stretch mb-5 mb-xl-8'
          />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
